import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import OnboardingCard from './components/Card';
import VerifySSOEmailForm from './components/Forms/VerifySSOEmailForm';
import OnboardingLayout from './components/Layout';

const VerifySSOEmail = () => {
  const params = useParams<{ email?: string; issuer?: string }>();
  const { issuer: samlConnection, email } = params;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirect = queryParams.get('redirect');

  return (
    <OnboardingLayout>
      <OnboardingCard>
        <VerifySSOEmailForm email={email} redirect={redirect} samlConnection={samlConnection} />
      </OnboardingCard>
    </OnboardingLayout>
  );
};

export default VerifySSOEmail;
